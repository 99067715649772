export const endpoints = {
  AUTHORIZATE: "/customer/",
  LOGIN: "/user/signin/local",
  SIGNUP: "/user/signup",
  GOOGLELOGIN: "/user/signin/google",
  GOOGLELOGINCALLBACK: "/user/signin/google/callback",
  FACEBOOKLOGIN: "/user/signin/facebook",
  FACEBOOKLOGINCALLBACK: "/user/signin/facebook/callback",
  UPDATEACCOUNT: "/customer/update",
  UPDATEBILLING: "/customer/update/billing",
  GETACCOUNT: "/customer/get/account",
  GETADDRESS: "/customer/get/address",
  ADDORDERITEM: "/order/add/item",
  GETORDERLIST: "/order/get/list",
  GETCART: "/cart/get",
  ADDCART: "/cart/add",
  REMOVECART: "/cart/remove",
  INCREASEQUANTITY: "/cart/increase",
  DECREASEQUANTITY: "/cart/decrease",
  CLEARCART: "/cart/clear",
  GETSHIPPINGMETHOD: "/shippingmethod/get/method",
  SETSHIPPINGPROCESS: "/shippinginfo/process",
  GETWISHLIST: "/wishlist/get",
  ADDWISHLIST: "/wishlist/add",
  REMOVEWISHLIST: "/wishlist/remove",
  CLEARWISHLIST: "/wishlist/clear",
};
