import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import menuPrimary from "../../../assets/data/menu.json";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const PanelMenu = () => {
  const [openKeys, setOpenKeys] = useState([]);

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const setting = useSelector((state) => state.setting);

  return (
    <div className="ps-panel__wrapper">
      <div className="ps-panel__header">
        <h3>Menu</h3>
      </div>
      <div className="ps-panel__content">
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className="menu--mobile-2"
        >
          {menuPrimary.menu_1.map((item) => {
            if (item.subMenu) {
              return (
                <SubMenu
                  key={item.text}
                  title={
                    <Link href={item.url}>
                      <a>{item.text}</a>
                    </Link>
                  }
                >
                  {item.subMenu.map((subItem) => (
                    <Menu.Item key={subItem.text}>
                      <Link href={subItem.url}>
                        <a>{subItem.text}</a>
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            } else if (item.megaContent) {
              return (
                <SubMenu
                  key={item.text}
                  title={
                    <Link href={item.url}>
                      <a>{item.text}</a>
                    </Link>
                  }
                >
                  {item.megaContent.map((megaItem) => (
                    <SubMenu
                      key={megaItem.heading}
                      title={<span>{megaItem.heading}</span>}
                    >
                      {megaItem.megaItems.map((megaSubItem) => (
                        <Menu.Item key={megaSubItem.text}>
                          <Link href={item.url}>
                            <a>{megaSubItem.text}</a>
                          </Link>
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item key={item.text}>
                  {item.type === "dynamic" ? (
                    <Link
                      href={`${item.url}/[pid]`}
                      as={`${item.url}/${item.endPoint}`}
                    >
                      <a>{item.text}</a>
                    </Link>
                  ) : (
                    <Link href={item.url} as={item.alias}>
                      <a>{item.text}</a>
                    </Link>
                  )}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </div>
    </div>
  );
};

export default PanelMenu;
