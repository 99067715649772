import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { notification } from "antd";
import Menu from "../Menu";

import menuData from "../../../../assets/data/menu.json";
import CurrencyDropdown from "../CurrencyDropdown";
import LanguageSwicher from "../LanguageSwicher";

const NavigationDefault = () => {
  // const [menuItems, setMenuItems] = useState(menuData.product_categories);

  // useEffect(() => {
  //   const fetchMenuItems = async () => {
  //     const response = await fetch("/api/menu/items");
  //     const data = await response.json();
  //     setMenuItems(data);
  //   };

  //   fetchMenuItems();
  // }, []);

  const handleFeatureWillUpdate = (e) => {
    e.preventDefault();
    notification.open({
      message: "Opp! Something went wrong.",
      description: "This feature has been updated later!",
      duration: 500,
    });
  };

  return (
    <nav className="navigation">
      <div className="ps-container">
        {/* <div className="navigation__left">
          <div className="menu--product-categories">
            <div className="menu__toggle">
              <i className="icon-menu"></i>
              <span> Shop by Department</span>
            </div>
            <div className="menu__content">
              <Menu data={menuItems} className="menu--dropdown" />
            </div>
          </div>
        </div> */}
        <div className="navigation__right">
          {/* <Menu data={menuData.menuPrimary.menu_1} className="menu" /> */}
          <ul className="menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/rental">Rental</Link>
            </li>
            {/* <li>
              <Link to="#">Service Center</Link>
            </li> */}
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="/blogs">Blogs</Link>
            </li>
          </ul>
          <ul className="navigation__extra">
            <li>
              <Link href="/vendor/become-a-vendor">Sell on Shakti Ev</Link>
            </li>
            <li>
              <Link href="/affiliate">Affiliate</Link>
            </li>
            {/* <li>
              <CurrencyDropdown />
            </li>
            <li>
              <LanguageSwicher />
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavigationDefault;
