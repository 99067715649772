import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logOut } from "../../../store/auth/action";

function AccountQuickLinks() {
  const accountLinks = [
    {
      text: "Account Information",
      url: "/account/user-information",
    },
    {
      text: "Notifications",
      url: "/account/notifications",
    },
    {
      text: "Invoices",
      url: "/account/invoices",
    },
    {
      text: "Address",
      url: "/account/addresses",
    },
    {
      text: "Recent Viewed Product",
      url: "/account/recent-viewed-product",
    },
    {
      text: "Shopping Cart",
      url: "/account/shopping-cart",
    },
    {
      text: "Wishlist",
      url: "/account/wishlist",
    },
  ];

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn); // Adjust this selector according to your state structure
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  if (isLoggedIn === true) {
    return (
      <div className="ps-block--user-account">
        <i className="icon-user"></i>
        <div className="ps-block__content">
          <ul className="ps-list--arrow">
            {accountLinks.map((link) => (
              <li key={link.text}>
                <Link to={link.url}>{link.text}</Link>
              </li>
            ))}
            <li className="ps-block__footer">
              <Link href="#" onClick={handleLogout}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ps-block--user-header">
        <div className="ps-block__left">
          <Link to="/account/login" aria-label="user Profile">
            <i className="icon-user"></i>
          </Link>
        </div>
        {/* <div className="ps-block__right">
          <Link to="/account/login">Login</Link>
          <Link to="/account/register">Register</Link>
        </div> */}
      </div>
    );
  }
}

export default AccountQuickLinks;
