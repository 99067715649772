import { all, call, put, takeEvery } from "redux-saga/effects";
import { polyfill } from "es6-promise";
import products from "../../assets/data/product.json";

import {
  actionTypes,
  getProductsByCityIdSuccess,
  getProductsByFeaturedSuccess,
  getProductsByLocationSuccess,
  getProductsError,
  getProductsSuccess,
  getSingleProductsSuccess,
} from "./action";
import axios from "axios";
import {
  API_BASE_URL,
  PRODUCT_BY_ID_URL,
  PRODUCT_LIST_BY_LOCATION_URL,
  PRODUCT_LIST_URL,
  PRODUCT_LIST_BY_CITYID_URL,
  FEATURED_URL,
} from "../../constants/apiConstants";

polyfill();

function* getProductsSaga() {
  try {
    // console.log("inside productsaga");
    const data = yield call(axios.get, API_BASE_URL + PRODUCT_LIST_URL);
    yield put(getProductsSuccess(data.data));
  } catch (err) {
    yield put(getProductsError(err));
  }
}

function* getProductById({ id }) {
  try {
    // const data = products;
    const data = yield call(
      axios.get,
      API_BASE_URL + PRODUCT_BY_ID_URL + `${id}`
    );
    console.log(data.data.data);
    yield put(getSingleProductsSuccess(data.data.data));
    // if (id !== undefined) {
    //   const filteredProduct = data.filter((product) => product.id === id);
    //   if (filteredProduct.length > 0) {
    //     yield put(getSingleProductsSuccess(filteredProduct[0]));
    //   }
    // } else {
    //   yield put(getProductsSuccess(data));
    // }
  } catch (err) {
    yield put(getProductsError(err));
  }
}

function* getProductByCategorySaga({ category }) {
  try {
    /*
     * Change this action by your API data
     */
    const data = products;
    if (category !== undefined) {
      const filteredProduct = data.filter((product) =>
        product.categories.some((cat) => cat.value == category)
      );

      yield put(getProductsSuccess(filteredProduct));
    } else {
      yield put(getProductsSuccess(data));
    }
  } catch (err) {
    yield put(getProductsError(err));
  }
}

function* getProductByPriceRangeSaga({ payload }) {
  try {
    /*
     * Change this action by your API data
     */
    const data = products;
    const filteredProduct = data.filter(
      (product) => product.price >= payload[0] && product.price <= payload[1]
    );
    yield put(getProductsSuccess(filteredProduct));
  } catch (err) {
    yield put(getProductsError(err));
  }
}

function* getProductByBrandSaga({ brand }) {
  try {
    /*
     * Change this action by your API data
     */
    const data = products;
    const filterBrand = brand;
    if (typeof brand !== "string") {
      const filterResult = data.filter(({ brand }) => {
        return brand.some(({ value }) => {
          return filterBrand.includes(value);
        });
      });
      yield put(getProductsSuccess(filterResult));
    } else {
      const filteredProduct = data.filter((product) =>
        product.brand.some((brandItem) => brandItem.value === brand)
      );
      yield put(getProductsSuccess(filteredProduct));
    }
  } catch (err) {
    yield put(getProductsError(err));
  }
}

function* getProductByLocationSaga({ city }) {
  try {
    console.log("in SAGA", city);
    // console.log({ city });
    // const data = products;
    const data = yield call(
      axios.post,
      API_BASE_URL + PRODUCT_LIST_BY_LOCATION_URL,
      city.location
    );
    console.log({ data });
    yield put(getProductsByLocationSuccess(data.data.allPopulatedProducts));
    // if (id !== undefined) {
    //   const filteredProduct = data.filter((product) => product.id === id);
    //   if (filteredProduct.length > 0) {
    //     yield put(getSingleProductsSuccess(filteredProduct[0]));
    //   }
    // } else {
    //   yield put(getProductsSuccess(data));
    // }
  } catch (err) {
    console.log({ err });
    yield put(getProductsError(err));
  }
}
function* getProductByCityIdSaga({ cityId }) {
  try {
    // console.log("in SAGA");
    // console.log({ cityId });
    // const data = products;
    const data = yield call(
      axios.get,
      API_BASE_URL + PRODUCT_LIST_BY_CITYID_URL + "?id=" + cityId
    );
    // console.log(data);
    yield put(getProductsByCityIdSuccess(data.data.cityProducts));
    // if (id !== undefined) {
    //   const filteredProduct = data.filter((product) => product.id === id);
    //   if (filteredProduct.length > 0) {
    //     yield put(getSingleProductsSuccess(filteredProduct[0]));
    //   }
    // } else {
    //   yield put(getProductsSuccess(data));
    // }
  } catch (err) {
    console.log({ err });
    yield put(getProductsError(err));
  }
}
function* getProductByKeywordSaga({ keyword }) {
  try {
    /*
     * Change this action by your API data
     */
    const data = products;
    let regexp = new RegExp(keyword.toLowerCase(), "g");
    const filteredProduct = data.filter((product) =>
      product.title.toLowerCase().match(regexp)
    );
    if (filteredProduct > 0) {
      yield put(getProductsSuccess(filteredProduct));
    } else {
      const emptyArr = [];
      yield put(getProductsSuccess(emptyArr));
    }
  } catch (err) {
    yield put(getProductsError(err));
  }
}
function* getProductsByFeatured() {
  try {
    console.log("inside featured");
    const data = yield call(axios.get, API_BASE_URL + FEATURED_URL);
    console.log("data", data.data);
    yield put(getProductsByFeaturedSuccess(data.data));
  } catch (err) {
    yield put(getProductsError(err));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.GET_PRODUCTS, getProductsSaga)]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_FEATURED, getProductsByFeatured),
  ]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_CATEGORY, getProductByCategorySaga),
  ]);
  yield all([
    takeEvery(
      actionTypes.GET_PRODUCTS_BY_PRICE_RANGE,
      getProductByPriceRangeSaga
    ),
  ]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_BRAND, getProductByBrandSaga),
  ]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_KEYWORD, getProductByKeywordSaga),
  ]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_LOCATION, getProductByLocationSaga),
  ]);
  yield all([
    takeEvery(actionTypes.GET_PRODUCTS_BY_CITYID, getProductByCityIdSaga),
  ]);
  yield all([takeEvery(actionTypes.GET_PRODUCT_BY_ID, getProductById)]);
}
