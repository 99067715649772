import React, { useEffect, useState } from "react";
// import { Select } from "antd";
import { Link } from "react-router-dom";
import ProductResult from "../../elements/products/ProductSearchResult";
// import products from "../../../assets/data/product.json";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setSelectedLocation } from "../../../store/location/action";
import { getProductsByLocation } from "../../../store/product/action";

// const { Option } = Select;

const MobileSearchHeader = () => {
  const navigate = useNavigate();
  const products = useSelector((state) => state.product.locationProducts);
  const [searchPanel, setSearchPanel] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("type=All");
  const [searchProducts, setSearchProducts] = useState([]);
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.location.userLocation);
  const locationData = useSelector((state) => state.location);
  const cityList = useSelector((state) => state.location.cityList);
  const onSelectOptions = (selectedItem) => {
    dispatch(setSelectedLocation(selectedItem));
  };
  const getData = () => {
    return cityList.map((cityObj, index) => {
      return {
        label: `${cityObj.name},${cityObj.state}`,
        value: index,
      };
    });
  };
  useEffect(() => {
    // console.log("in city list useEffect", cityList[userLocation.value]);
    // if (cityList.length > 0) {
    //   dispatch(getProductsByLocation(cityList[userLocation.value]));
    // }
  }, [cityList, locationData]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "40px",
    }),
  };

  const searchByProductName = (category, keyword) => {
    console.log({ category, keyword, products });
    const categoryValue = category.split("=")[1];
    console.log({ categoryValue });
    const matches = (
      categoryValue != "All" && products
        ? products.filter(
            (product) =>
              product.category === categoryValue ||
              product.speedType === categoryValue
          )
        : products
    ).filter(
      (product) =>
        product.title.toLowerCase().includes(keyword.toLowerCase()) ||
        product.brand.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setSearchProducts(matches);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // keyword = e.target.value;
    setKeyword(e.target.value);
    setSearchPanel(true);
    searchByProductName(category, keyword);
  };
  const handleCategory = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchPanel(false);
    // const keyword = e.target.value;

    navigate(`/search?keyword=${keyword}&${category}`);
  };
  const exampleCategories = [
    { value: "type=All", label: "All" },
    { value: "type=Bike", label: "Bike" },
    { value: "type=Scooter", label: "Scooter" },
    { value: "speedType=High", label: "High Speed" },
    { value: "speedType=Low", label: "Low Speed" },
  ];
  return (
    // <form
    //   className="ps-form--quick-search"
    //   method="get"
    //   action="/"
    //   onSubmit={handleSubmit}
    // >
    //   <div className="ps-form__categories">
    //     <select className="form-control" onChange={handleCategory}>
    //       {exampleCategories.map((option) => (
    //         <option value={option.value} key={option.label}>
    //           {option.label}
    //         </option>
    //       ))}
    //     </select>
    //   </div>
    //   <input
    //     className="form-control"
    //     type="text"
    //     placeholder="I'm shopping for..."
    //     onChange={handleSearch}
    //     value={keyword}
    //     onBlur={() => {
    //       // setKeyword("");
    //       setSearchPanel(false);
    //     }}
    //   />
    //   <button onClick={handleSubmit}>Search</button>
    //   <div
    //     className={`ps-panel--search-result${
    //       searchPanel && searchPanel === true ? " active " : ""
    //     }`}
    //   >
    //     <div className="ps-panel__content">
    //       {searchProducts.length > 0 ? (
    //         searchProducts.map((product) => (
    //           <ProductResult
    //             product={product}
    //             key={product._id}
    //             setSearchPanel={setSearchPanel}
    //           />
    //         ))
    //       ) : (
    //         <span>Not found! Try with another keyword.</span>
    //       )}
    //     </div>
    //     <div className="ps-panel__footer text-center">
    //       <Link
    //         to={`/search?keyword=${keyword}&${category}`}
    //         onClick={() => setSearchPanel(false)}
    //       >
    //         See all results
    //       </Link>
    //     </div>
    //   </div>
    // </form>
    <form
      className="ps-form--search-mobile"
      action="/"
      method="get"
      onSubmit={handleSubmit}
    >
      <div className="form-group--nest">
        <input
          className="form-control inputSearch rounded-left"
          type="text"
          placeholder="Search something..."
          onChange={handleSearch}
          value={keyword}
          onBlur={() => {
            // setKeyword("");
            setSearchPanel(false);
          }}
        />
        <button onClick={handleSubmit} className="rounded-right">
          <i className="icon-magnifier"></i>
        </button>
        <div
          className={`ps-panel--search-result${
            searchPanel && searchPanel === true ? " active " : ""
          }`}
        >
          <div className="ps-panel__content">
            {searchProducts.length > 0 ? (
              searchProducts.map((product) => (
                <ProductResult
                  product={product}
                  key={product._id}
                  setSearchPanel={setSearchPanel}
                />
              ))
            ) : (
              <span>Not found! Try with another keyword.</span>
            )}
          </div>
          <div className="ps-panel__footer text-center">
            <Link
              to={`/search?keyword=${keyword}&${category}`}
              onClick={() => setSearchPanel(false)}
            >
              See all results
            </Link>
          </div>
        </div>
        <div className="ml-4 ">
          <Select
            value={userLocation}
            onChange={onSelectOptions}
            styles={customStyles}
            // isDisabled={isDisabled}
            // isLoading={isLoading}
            // isClearable={isClearable}
            // isRtl={isRtl}
            // isSearchable={isSearchable}
            name="city"
            options={getData()}
          />
        </div>
      </div>
    </form>
  );
};

export default MobileSearchHeader;
