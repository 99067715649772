// actions.js
export const actionTypes = {
  FETCH_BRANDS_REQUEST: "FETCH_BRANDS_REQUEST",
  FETCH_BRANDS_SUCCESS: "FETCH_BRANDS_SUCCESS",
  FETCH_BRANDS_FAILURE: "FETCH_BRANDS_FAILURE",
};
export const fetchBrandsRequest = () => ({
  type: actionTypes.FETCH_BRANDS_REQUEST,
});

export const fetchBrandsSuccess = (brands) => ({
  type: actionTypes.FETCH_BRANDS_SUCCESS,
  payload: brands,
});

export const fetchBrandsFailure = (error) => ({
  type: actionTypes.FETCH_BRANDS_FAILURE,
  payload: error,
});
