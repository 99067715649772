import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import { default as store, default as persistor } from "./store/store";
import App from "./App";
import configureStore from "./store/store";

import "./scss/style.scss";
import "react-datepicker/dist/react-datepicker.css";

import { PersistGate } from "redux-persist/integration/react";
// import { persistStore } from "redux-persist";
// let persistor = persistStore(store);
const { store, persistor } = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
