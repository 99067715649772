import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import PanelMenu from "../../panel/PanelMenu";
import PanelCartMobile from "../../panel/PanelCartMobile";
import PanelSearch from "../../panel/PanelSearch";
import PanelCategories from "../../panel/PanelCategories";
import { Link } from "react-router-dom";
import rental from "../../../../assets/images/rental/rental.svg";

const NavigationList = () => {
  const [menuDrawer, setMenuDrawer] = useState(false);
  const [cartDrawer, setCartDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);
  const [categoriesDrawer, setCategoriesDrawer] = useState(false);

  const setting = useSelector((state) => state.setting);

  const handleDrawerClose = () => {
    setMenuDrawer(false);
    setCartDrawer(false);
    setSearchDrawer(false);
    setCategoriesDrawer(false);
  };

  const handleShowHome = () => {
    // setMenuDrawer(!menuDrawer);
    // setCartDrawer(false);
    setSearchDrawer(false);
    setCategoriesDrawer(false);
  };

  const handleShowRental = () => {
    // setMenuDrawer(false);
    // setCartDrawer(!cartDrawer);
    setSearchDrawer(false);
    setCategoriesDrawer(false);
  };

  const handleShowSearchDrawer = () => {
    setMenuDrawer(false);
    setCartDrawer(false);
    setSearchDrawer(!searchDrawer);
    setCategoriesDrawer(false);
  };

  const handleShowCategoriesDrawer = () => {
    setMenuDrawer(false);
    setCartDrawer(false);
    setSearchDrawer(false);
    setCategoriesDrawer(!categoriesDrawer);
  };

  return (
    <div className="navigation--list">
      <Drawer
        className="ps-panel--mobile"
        placement="right"
        closable={false}
        onClose={handleDrawerClose}
        visible={menuDrawer}
      >
        <PanelMenu />
      </Drawer>
      <Drawer
        className="ps-panel--mobile"
        placement="right"
        closable={false}
        onClose={handleDrawerClose}
        visible={cartDrawer}
      >
        <PanelCartMobile />
      </Drawer>
      <Drawer
        className="ps-panel--mobile"
        placement="right"
        closable={false}
        onClose={handleDrawerClose}
        visible={searchDrawer}
      >
        <PanelSearch />
      </Drawer>
      <Drawer
        className="ps-panel--mobile"
        placement="right"
        closable={false}
        onClose={handleDrawerClose}
        visible={categoriesDrawer}
      >
        <PanelCategories
          handleShowCategoriesDrawer={handleShowCategoriesDrawer}
        />
      </Drawer>
      <div className="navigation__content">
        <Link
          to="/"
          className={`navigation__item ${menuDrawer === true ? "active" : ""}`}
          onClick={handleShowHome}
        >
          <i className="icon-menu"></i>
          <span> Home</span>
        </Link>
        <Link
          className={`navigation__item ${cartDrawer === true ? "active" : ""}`}
          to="/rental"
          onClick={handleShowRental}
        >
          {/* <i className="icon-bag2"></i> */}
          <img
            className="mb-2"
            src={rental}
            alt="bike"
            style={{
              color: "gray",
              // verticalAlign: "middle",
              height: "24px",
              width: "24px",
            }}
          />
          <span> Rental</span>
        </Link>
        <a
          className={`navigation__item ${
            categoriesDrawer === true ? "active" : ""
          }`}
          onClick={handleShowCategoriesDrawer}
        >
          <i className="icon-list4"></i>
          <span> Pages</span>
        </a>
        <a
          className={`navigation__item ${
            searchDrawer === true ? "active" : ""
          }`}
          onClick={handleShowSearchDrawer}
        >
          <i className="icon-magnifier"></i>
          <span> Search</span>
        </a>
      </div>
    </div>
  );
};

export default NavigationList;
