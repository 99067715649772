import axios from "axios";
import { API_BASE_URL } from "../constants/apiConstants";

export const httpCALL = async (url) => {
  const data = await axios.get(url);
  return data;
};
export const httpGET = async (url, header) => {
  const data = await axios.get(API_BASE_URL + url, header);
  return data;
};

export const httpPOST = async (url, body, header) => {
  const data = await axios.post(API_BASE_URL + url, body, header);
  return data;
};
