export const actionTypes = {
  GET_RENT_CITY: "GET_RENT_CITY",
  SET_RENT_CITY: "SET_RENT_CITY",
  GET_RENT_HUB: "GET_RENT_HUB",
  SET_RENT_HUB: "SET_RENT_HUB",
  GET_START_DATE: "GET_START_DATE",
  SET_START_DATE: "SET_START_DATE",
  GET_END_DATE: "GET_END_DATE",
  SET_END_DATE: "SET_END_DATE",
  GET_SELECTED_CATEGORY: "GET_SELECTED_CATEGORY",
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  GET_DAYSCOUNT: "GET_DAYSCOUNT",
  SET_DAYSCOUNT: "SET_DAYSCOUNT",
};

export function getRentCity() {
  return { type: actionTypes.GET_RENT_CITY };
}

export function setRentCity(payload) {
  return {
    type: actionTypes.SET_RENT_CITY,
    payload,
  };
}
export function getRentHub() {
  return { type: actionTypes.GET_RENT_HUB };
}

export function setRentHub(payload) {
  return {
    type: actionTypes.SET_RENT_HUB,
    payload,
  };
}
export function getRentStartDate() {
  return { type: actionTypes.GET_START_DATE };
}

export function setRentStartDate(payload) {
  return {
    type: actionTypes.SET_START_DATE,
    payload,
  };
}
export function getRentEndDate() {
  return { type: actionTypes.GET_END_DATE };
}

export function setRentEndDate(payload) {
  return {
    type: actionTypes.SET_END_DATE,
    payload,
  };
}
export function getSelectedCategory() {
  return { type: actionTypes.GET_SELECTED_CATEGORY };
}

export function setSelectedCategory(payload) {
  return {
    type: actionTypes.SET_SELECTED_CATEGORY,
    payload,
  };
}
export function getDaysCount() {
  return { type: actionTypes.GET_DAYSCOUNT };
}

export function setDaysCount(payload) {
  return {
    type: actionTypes.SET_DAYSCOUNT,
    payload,
  };
}
