// actions.js
export const actionTypes = {
  SUBMIT_ENQUIRY_FORM_REQUEST: "SUBMIT_ENQUIRY_FORM_REQUEST",
  // SUBMIT_ENQUIRY_FORM_SUCCESS: "SUBMIT_ENQUIRY_FORM_SUCCESS",
  // SUBMIT_ENQUIRY_FORM_FAILURE: "SUBMIT_ENQUIRY_FORM_FAILURE",
  SUBMIT_CONTACT_FORM_REQUEST: "SUBMIT_CONTACT_FORM_REQUEST",
  // SUBMIT_CONTACT_FORM_SUCCESS: "SUBMIT_CONTACT_FORM_SUCCESS",
  // SUBMIT_CONTACT_FORM_FAILURE: "SUBMIT_CONTACT_FORM_FAILURE",
};
// Action creators for Enquiry Form
export const submitEnquiryFormRequest = (request) => ({
  type: actionTypes.SUBMIT_ENQUIRY_FORM_REQUEST,
  payload: request,
});

// export const submitEnquiryFormSuccess = () => ({
//   type: actionTypes.SUBMIT_ENQUIRY_FORM_SUCCESS,
// });

// export const submitEnquiryFormFailure = (error) => ({
//   type: actionTypes.SUBMIT_ENQUIRY_FORM_FAILURE,
//   payload: error,
// });

// Action creators for Contact Form
export const submitContactFormRequest = (request) => ({
  type: actionTypes.SUBMIT_CONTACT_FORM_REQUEST,
  payload: request,
});

// export const submitContactFormSuccess = () => ({
//   type: actionTypes.SUBMIT_CONTACT_FORM_SUCCESS,
// });

// export const submitContactFormFailure = (error) => ({
//   type: actionTypes.SUBMIT_CONTACT_FORM_FAILURE,
//   payload: error,
// });
