import React, { useEffect } from "react";
import CurrencyDropdown from "./headerComponents/CurrencyDropdown";
import { Link } from "react-router-dom";
import LanguageSwicher from "./headerComponents/LanguageSwicher";
import MobileHeaderActions from "./headerComponents/MobileHeaderActions";
import Shaktilogo from "../../assets/images/logonobackground.png";
import SearchHeader from "./headerComponents/SearchHeader";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLocation } from "../../store/location/action";
import { getProductsByLocation } from "../../store/product/action";
import MobileSearchHeader from "./headerComponents/MobileSearchHeader";
const HeaderMobile = () => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.location.userLocation);
  const locationData = useSelector((state) => state.location);
  const cityList = useSelector((state) => state.location.cityList);
  const onSelectOptions = (selectedItem) => {
    dispatch(setSelectedLocation(selectedItem));
  };
  const getData = () => {
    return cityList.map((cityObj, index) => {
      return {
        label: `${cityObj.name},${cityObj.state}`,
        value: index,
      };
    });
  };
  useEffect(() => {
    // console.log("in city list useEffect", cityList[userLocation.value]);
    // if (cityList.length > 0) {
    //   dispatch(getProductsByLocation(cityList[userLocation.value]));
    // }
  }, [cityList, locationData]);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // marginLeft: "12px",
      height: "42px",
      // width: "180px",
      // Set the desired width here
    }),
  };
  return (
    <header className="header header--mobile">
      <div className="p-2 header__top" style={{ backgroundColor: "#f4f5f5" }}>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <ul className="navigation__extra">
              <li>
                <Link to="tel:02269621007">
                  <i className="icon-telephone px-2"></i>02269621007
                </Link>
              </li>
              {/* <li>
                <Link to="mailto:info@shaktiev.com">
                  <i className="icon-envelope px-2"></i>info@shaktiev.com
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="">
            <ul className="navigation__extra">
              <li>
                {/* <Link to="/vendor/become-a-vendor">Sell on ShaktiEV</Link> */}
                <Link
                  to="https://www.facebook.com/profile.php?id=100089639061493"
                  aria-label="facebook Profile"
                >
                  <i className="fa fa-facebook px-2"></i>
                </Link>

                <Link
                  to="https://twitter.com/shakti_ev"
                  aria-label="twitter Profile"
                >
                  <i className="fa fa-twitter px-2"></i>
                </Link>

                <Link
                  to="https://www.linkedin.com/company/shakti-electric-vehicle/"
                  aria-label="linkedin Profile"
                >
                  <i className="fa fa-linkedin px-2"></i>
                </Link>

                <Link
                  to="https://www.youtube.com/@shaktiev"
                  aria-label="youtube Profile"
                >
                  <i className="fa fa-youtube-play px-2"></i>
                </Link>

                <Link
                  to="https://instagram.com/shakti_ev?igshid=YmMyMTA2M2Y="
                  aria-label="instagram Profile"
                >
                  <i className="fa fa-instagram px-2"></i>
                </Link>
              </li>
              <li className="mr-2">
                <Link to="/contact-us">CONTACT US</Link>
              </li>
              {/* <li>
              <CurrencyDropdown />
            </li>
            <li>
              <LanguageSwicher />
            </li> */}
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="p-2 header__top ">
        <div className="header__left">
          <p>Welcome to ShaktiEV !</p>
        </div>

        <div className="header__right">
          <ul className="navigation__extra">
            <li>
              <Link to="/vendor/become-a-vendor">Sell on ShaktiEV</Link>
            </li>
            <li>
              <Link href="/account/order-tracking">Track your order</Link>
            </li>
            <li>
              <CurrencyDropdown />
            </li>
            <li>
              <LanguageSwicher />
            </li>
          </ul>
        </div>
      </div> */}
      <div className="navigation--mobile">
        <div className="navigation__left">
          <Link to="/" className="ps-logo">
            <img src={Shaktilogo} alt="ShaktiEv" />
          </Link>
        </div>
        <div className="navigation__center">
          {/* <SearchHeader /> */}
          {/* <form className="ps-form--search-mobile" action="/" method="get">
            <div className="form-group--nest">
              <input
                className="form-control"
                type="text"
                placeholder="Search something..."
              />
              <button>
                <i className="icon-magnifier"></i>
              </button>
            </div>
          </form> */}
          <MobileSearchHeader />
        </div>
        <div className="navigation__right">
          <MobileHeaderActions />
        </div>
      </div>
      <div className="ps-search--mobile">
        {/* <form className="ps-form--search-mobile" action="/" method="get">
          <div className="form-group--nest">
            <input
              className="form-control"
              type="text"
              placeholder="Search something..."
            />
            <button>
              <i className="icon-magnifier"></i>
            </button>
            <div className="ml-4">
              <Select
                value={userLocation}
                onChange={onSelectOptions}
                styles={customStyles}
                // isDisabled={isDisabled}
                // isLoading={isLoading}
                // isClearable={isClearable}
                // isRtl={isRtl}
                // isSearchable={isSearchable}
                name="city"
                options={getData()}
              />
            </div>
          </div>
        </form> */}
        <MobileSearchHeader />
      </div>
    </header>
  );
};

export default HeaderMobile;
