// src/reducers/locationReducer.js
const initialState = {
  userLocation: localStorage.getItem("selectedLocation")
    ? JSON.parse(localStorage.getItem("selectedLocation"))
    : null,
  cityList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOCATION_DATA": {
      console.log(state.userLocation == null);
      if (state.userLocation == null)
        return { ...state, userLocation: action.payload };
      return { ...state };
    }
    case "SET_SELECTED_LOCATION": {
      localStorage.setItem("selectedLocation", JSON.stringify(action.payload));
      return { ...state, userLocation: action.payload };
    }
    case "SET_CITY_LIST_DATA":
      return { ...state, cityList: action.payload };
    default:
      return state;
  }
};

export default reducer;
