import React from "react";
import FooterWidgets from "./footerComponents/FooterWidgets";
// import FooterLinks from "./footerComponents/FooterLinks";
import FooterCopyright from "./footerComponents/FooterCopyright";

const FooterFullwidth = () => (
  <footer className="ps-footer">
    <div className="ps-container">
      <FooterWidgets />
      {/* <FooterLinks /> */}
      <FooterCopyright />
    </div>
  </footer>
);

export default FooterFullwidth;
