// saga.js

import { put, call, all, takeEvery } from "redux-saga/effects";
import {
  actionTypes,
  fetchBannersFailure,
  fetchBannersSuccess,
} from "./action";
import { API_BASE_URL, BANNERS_URL } from "../../constants/apiConstants";

// Assuming you have an API function to fetch banners
const apiFetchBanners = async () => {
  try {
    console.log("in fetch banner");
    const response = await fetch(`${API_BASE_URL}${BANNERS_URL}`);
    const data = await response.json();
    return data.bannerList;
  } catch (error) {
    throw error;
  }
};

// Worker Saga: Fetch Banners
function* fetchBanners() {
  try {
    console.log("in bannerssaga");
    const banners = yield call(apiFetchBanners);
    yield put(fetchBannersSuccess(banners));
  } catch (error) {
    yield put(fetchBannersFailure(error.message));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.FETCH_BANNERS_REQUEST, fetchBanners)]);
}
