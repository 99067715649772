import { actionTypes, setCityListData } from "./action";
import { call, put, takeEvery } from "redux-saga/effects";
import { setLocationData } from "./action";
import { wait } from "@testing-library/user-event/dist/utils";
import { httpCALL, httpGET, httpPOST } from "../../utils/http";
import { CITY_LIST_URL } from "../../constants/apiConstants";

function* fetchLocationDataAsync() {
  // try {
  //   console.log(response.data);
  //   const userLocation = {
  //     location: {
  //       coordinates: [response.data.longitude, response.data.latitude],
  //       type: "Point",
  //     },
  //     name: response.data.city,
  //     state: response.data.region_code,
  //   };
  //   yield put(setLocationData(response.userLocation));
  // } catch (error) {
  //   console.error("Error fetching location data:", error);
  // }
}

function* fetchCityListDataAsync() {
  try {
    const response = yield httpGET(CITY_LIST_URL);
    // console.log(response.data);
    // const locationResponse = yield httpCALL("https://ipapi.co/json/");
    // const userLocation = {
    //   location: {
    //     coordinates: [
    //       locationResponse.data.longitude,
    //       locationResponse.data.latitude,
    //     ],
    //     type: "Point",
    //   },
    //   name: locationResponse.data.city,
    //   state: locationResponse.data.region_code,
    // };
    const userLocation = {
      location: {
        coordinates: [73.7898, 19.9975],
        type: "Point",
      },
      name: "Nashik",
      state: "MH",
    };
    let index = 0;
    const isCityExist = response.data.findIndex(
      (cityObj) =>
        cityObj.name == userLocation.name && cityObj.state == userLocation.state
    );
    // console.log({ isCityExist });
    const cityListArray = [...response.data];
    if (isCityExist < 0) {
      index = cityListArray.length;
      // console.log(index);
      cityListArray.push(userLocation);
    } else {
      index = isCityExist;
    }
    yield put(setCityListData(cityListArray));
    yield put(
      setLocationData({
        label: `${userLocation.name},${userLocation.state}`,
        value: index,
      })
    );
  } catch (error) {
    console.error("Error fetching location data:", error);
  }
}

function* locationSaga() {
  yield takeEvery(actionTypes.FETCH_LOCATION_DATA, fetchLocationDataAsync);
  yield takeEvery(actionTypes.FETCH_CITY_LIST, fetchCityListDataAsync);
}

export default locationSaga;
