// saga.js

import { put, call, all, takeEvery } from "redux-saga/effects";

import { actionTypes, fetchBrandsFailure, fetchBrandsSuccess } from "./action";
import { API_BASE_URL, BRANDS_URL } from "../../constants/apiConstants";

// Assuming you have an API function to fetch brands
const apiFetchBrands = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}${BRANDS_URL}`);
    const data = await response.json();
    return data.brandList;
  } catch (error) {
    throw error;
  }
};

// Worker Saga: Fetch Brands
function* fetchBrands() {
  try {
    const brands = yield call(apiFetchBrands);
    yield put(fetchBrandsSuccess(brands));
  } catch (error) {
    yield put(fetchBrandsFailure(error.message));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.FETCH_BRANDS_REQUEST, fetchBrands)]);
}
