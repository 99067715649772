import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavigationDefault from "./headerComponents/navigation/NavigationDefault";
import HeaderActions from "./headerComponents/HeaderActions";
import MenuCategories from "./headerComponents/MenuCategories";
import SearchHeader from "./headerComponents/SearchHeader";
// import logo from "../../assets/images/ShaktiEvtransparentbglogo.png";
import logo from "../../assets/images/logonobackground.png";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  setLocationData,
  setSelectedLocation,
} from "../../store/location/action";
import { getProductsByLocation } from "../../store/product/action";
const HeaderDefault = () => {
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  const onSelectOptions = (selectedItem) => {
    dispatch(setSelectedLocation(selectedItem));
  };

  const cityList = useSelector((state) => state.location.cityList);
  const locationData = useSelector((state) => state.location);
  const userLocation = useSelector((state) => state.location.userLocation);
  console.log({ cityList }, { userLocation });
  const getData = () => {
    return cityList.map((cityObj, index) => {
      return {
        label: `${cityObj.name},${cityObj.state}`,
        value: index,
      };
    });
  };
  useEffect(() => {
    // console.log("in city list useEffect", cityList[userLocation.value]);
    if (cityList.length > 0) {
      dispatch(getProductsByLocation(cityList[userLocation.value]));
    }
  }, [cityList, locationData]);
  // console.log({ cityList });
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    let number =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    setIsSticky(number >= 300);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // marginLeft: "12px",
      height: "42px",
      // width: "180px",
      // Set the desired width here
    }),
  };
  return (
    <header
      className={`header header--1 ${isSticky ? "header--sticky" : ""}`}
      data-sticky={isSticky}
      id="headerSticky"
    >
      <div className="p-2 header__top" style={{ backgroundColor: "#f4f5f5" }}>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <ul className="navigation__extra">
              <li>
                <Link to="tel:02269621007">
                  <i className="icon-telephone px-2"></i>02269621007
                </Link>
              </li>
              {/* <li>
                <Link to="mailto:info@shaktiev.com">
                  <i className="icon-envelope px-2"></i>info@shaktiev.com
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="">
            <ul className="navigation__extra">
              <li>
                {/* <Link to="/vendor/become-a-vendor">Sell on ShaktiEV</Link> */}
                <Link
                  to="https://www.facebook.com/profile.php?id=100089639061493"
                  aria-label="facebook Profile"
                >
                  <i className="fa fa-facebook px-2"></i>
                </Link>

                <Link
                  to="https://twitter.com/shakti_ev"
                  aria-label="Twitter Profile"
                >
                  <i className="fa fa-twitter px-2"></i>
                </Link>

                <Link
                  to="https://www.linkedin.com/company/shakti-electric-vehicle/"
                  aria-label="linkedin Profile"
                >
                  <i className="fa fa-linkedin px-2"></i>
                </Link>

                <Link
                  to="https://www.youtube.com/@shaktiev"
                  aria-label="youtube Profile"
                >
                  <i className="fa fa-youtube-play px-2"></i>
                </Link>

                <Link
                  to="https://instagram.com/shakti_ev?igshid=YmMyMTA2M2Y="
                  aria-label="instagram Profile"
                >
                  <i className="fa fa-instagram px-2"></i>
                </Link>
              </li>
              <li className="mr-2">
                <Link to="/contact-us">CONTACT US</Link>
              </li>
              {/* <li>
              <CurrencyDropdown />
            </li>
            <li>
              <LanguageSwicher />
            </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="header__top">
        <div className="ps-container header__space">
          <div className="header__left">
            <Link to="/" className="ps-logo">
              <img src={logo} alt="ShaktiEV" />
            </Link>
            <div className="menu--product-categories">
              <div className="menu__toggle">
                <i className="icon-menu"></i>
                <span> Shop by Department</span>
              </div>
              <div className="menu__content">
                <MenuCategories />
              </div>
            </div>
          </div>
          <div className="header__center">
            <SearchHeader />
            <div className="ml-4">
              <Select
                value={userLocation}
                onChange={onSelectOptions}
                aria-labelledby="chooseCategory"
                styles={customStyles}
                // isDisabled={isDisabled}
                // isLoading={isLoading}
                // isClearable={isClearable}
                // isRtl={isRtl}
                // isSearchable={isSearchable}
                name="city"
                options={getData()}
              />
            </div>
          </div>

          <div className="header__right">
            <HeaderActions />
          </div>
        </div>
      </div>
      <NavigationDefault />
    </header>
  );
};

export default HeaderDefault;
