import { getEndDate, getStartDate } from "../../utils/methods";
import { actionTypes } from "./action";
const currentDate = new Date();
export const initRent = {
  rentCity: {
    label: "Nashik",
    value: 0,
  },
  rentHub: {
    label: "Nashik Road (Railway Station)",
    value: 0,
  },

  rentStartDate: getStartDate(),
  rentEndDate: getEndDate(),
  selectedCategory: "",
  noOfDays: "1",
};

function reducer(state = initRent, action) {
  switch (action.type) {
    case actionTypes.GET_RENT_CITY:
      return state.rentCity;

    case actionTypes.SET_RENT_CITY:
      console.log({ action });
      return {
        ...state,
        rentCity: action.payload,
      };
    case actionTypes.GET_RENT_HUB:
      return state.rentHub;

    case actionTypes.SET_RENT_HUB:
      console.log({ action });
      return {
        ...state,
        rentHub: action.payload,
      };
    case actionTypes.GET_START_DATE:
      return state.rentHub;

    case actionTypes.SET_START_DATE:
      console.log({ action });
      return {
        ...state,
        rentStartDate: action.payload,
      };
    case actionTypes.GET_END_DATE:
      return state.rentHub;

    case actionTypes.SET_END_DATE:
      console.log({ action });
      return {
        ...state,
        rentEndDate: action.payload,
      };
    case actionTypes.GET_SELECTED_CATEGORY:
      return state.selectedCategory;

    case actionTypes.SET_SELECTED_CATEGORY:
      console.log({ action });
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case actionTypes.GET_DAYSCOUNT:
      return state.noOfDays;

    case actionTypes.SET_DAYSCOUNT:
      console.log({ action });
      return {
        ...state,
        noOfDays: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
