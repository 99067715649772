import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const PanelCategories = ({ handleShowCategoriesDrawer }) => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const shopCategories = [
    {
      text: "Home",
      url: "/home",
    },
    {
      text: "About Us",
      url: "/about-us",
    },
    {
      text: "Contact Us",
      url: "/contact-us",
    },
    {
      text: "Rental",
      url: "/rental",
    },
    {
      text: "Nashik Products",
      url: "/nashik?type=All",
    },
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (!latestOpenKey) {
      setOpenKeys([]);
    } else {
      setOpenKeys([latestOpenKey]);
    }
  };

  return (
    <div className="ps-panel__wrapper">
      <div className="ps-panel__header">
        <h3>Categories</h3>
      </div>
      <div className="ps-panel__content">
        <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
          {shopCategories.map((category) => (
            <Menu.Item key={category.text}>
              <Link
                to={category.url}
                onClick={() => handleShowCategoriesDrawer()}
              >
                <a>{category.text}</a>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
};

export default PanelCategories;
