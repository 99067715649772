import { all, put, takeLatest, takeEvery, call } from "redux-saga/effects";
import { polyfill } from "es6-promise";

import blogGrid from "../../assets/data/blog-grid.json";

import {
  actionTypes,
  getPostsSuccess,
  getFeaturedPostSuccess,
  getPostCategoriesSuccess,
  getRecentPostsSuccess,
  getPostsError,
  getRecentPostsError,
  getFeaturedPostError,
  getPostCategoriesError,
  getSingleBlogSuccess,
} from "./action";
import {
  API_BASE_URL,
  BLOGS_URL,
  BLOG_BY_TITLE_URL,
} from "../../constants/apiConstants";
import axios from "axios";

polyfill();

function* getPostsSaga() {
  try {
    // const data = blogGrid.posts;
    const data = yield call(axios.get, API_BASE_URL + BLOGS_URL);
    // console.log(data.data.blogsList);
    yield put(getPostsSuccess(data.data.blogsList));
  } catch (err) {
    yield put(getPostsError(err));
  }
}

function* getBlogByTitle({ title }) {
  try {
    // const data = products;
    console.log("in get blog by title saga");
    const data = yield call(
      axios.get,
      API_BASE_URL + BLOG_BY_TITLE_URL + title
    );
    console.log("data blog", data.data);
    yield put(getSingleBlogSuccess(data.data));
  } catch (err) {
    yield put(getPostsError(err));
  }
}

function* getFeaturedPostSaga() {
  try {
    const data = blogGrid.postFeatures;
    yield put(getFeaturedPostSuccess(data));
  } catch (err) {
    yield put(getFeaturedPostError(err));
  }
}
function* getRecentPostSaga() {
  try {
    const data = blogGrid.recentPosts;
    yield put(getRecentPostsSuccess(data));
  } catch (err) {
    yield put(getRecentPostsError(err));
  }
}

function* getPostCategories() {
  try {
    const data = blogGrid.blogCategories;
    yield put(getPostCategoriesSuccess(data));
  } catch (err) {
    yield put(getPostCategoriesError(err));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.GET_POSTS, getPostsSaga),
    takeEvery(actionTypes.GET_FEATURED_POST, getFeaturedPostSaga),
    takeEvery(actionTypes.GET_RECENT_POSTS, getRecentPostSaga),
    takeEvery(actionTypes.GET_POST_CATEGORIES, getPostCategories),
    takeEvery(actionTypes.GET_BLOG_BY_TITLE, getBlogByTitle),
  ]);
}
