import React from "react";

function FloatingButton() {
  const handlePhoneCall = () => {
    window.location.href = "tel:02269621007"; // Replace +1234567890 with your phone number
  };

  const handleWhatsApp = () => {
    window.location.href = "https://wa.me/02269621007"; // Replace 1234567890 with your WhatsApp number
  };

  return (
    <div className="floating-button-container">
      <button
        // style={{ fontSize: "30px" }}
        className="floating-button floating-call"
        onClick={handlePhoneCall}
        aria-label="Make phone call"
      >
        <i className="icon-telephone "></i>
      </button>
      <button
        className="floating-button floating-whatsapp"
        onClick={handleWhatsApp}
        aria-label="Make whatsapp message"
      >
        <i
          //   style={{ fontSize: "30px" }}
          className="fa fa-whatsapp "
        ></i>
      </button>
    </div>
  );
}

export default FloatingButton;
