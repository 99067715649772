import { combineReducers } from "redux";
import post from "./post/reducer";
import product from "./product/reducer";
import setting from "./setting/reducer";
import cart from "./cart/reducer";
import compare from "./compare/reducer";
import auth from "./auth/reducer";
import wishlist from "./wishlist/reducer";
import order from "./order/reducer";
import shipping from "./shipping/reducer";
import location from "./location/reducer";
import banner from "./banner/reducer";
import brand from "./brand/reducer";
import form from "./form/reducer";
import rent from "./rent/reducer";

export default combineReducers({
  auth,
  post,
  product,
  location,
  setting,
  cart,
  compare,
  wishlist,
  order,
  shipping,
  banner,
  brand,
  form,
  rent,
});
