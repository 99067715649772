import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import HeaderDefault from "./components/header/HeaderDefault";
// import Home from "./pages/Home";
import "./scss/home-default.scss";
import HeaderMobile from "./components/header/HeaderMobile";
import NavigationList from "./components/header/headerComponents/navigation/NavigationList";
// import SubscribePopup from "./components/header/SubscribePopup";
// import RentalPage from "./pages/rental";
import FooterFullwidth from "./components/footer/FooterFullwidth";
// import AboutUsPage from "./pages/about-us";
// import ContactUsPage from "./pages/contact-us";
// import FaqsPage from "./pages/faqs";
// import Page404 from "./pages/page-404";
// import BecomeAVendorPage from "./pages/vendor/become-a-vendor";
// import VendorStorePage from "./pages/vendor/vendor-store";
// import StoreList from "./components/vendor/StoreList";
// import StoreList2 from "./components/vendor/StoreList2";
// import ComparePage from "./pages/account/compare";
// import ProductDefaultPage from "./pages/product/default";
// import WishlistPage from "./pages/account/wishlist";
// import ShoppingCartPage from "./pages/account/shopping-cart";
// import CheckoutPage from "./pages/account/checkout";
// import axios from "axios";
import { fetchCityListData } from "./store/location/action";
import { useDispatch } from "react-redux";
// import PrivacyPolicyPage from "./pages/privacy-policy";
// import TermsConditionsPage from "./pages/terms-conditions";
// import ShippingPage from "./pages/shipping";
// import RefundReturnPage from "./pages/refund-return";

// import RegisterPage from "./pages/account/register";
// import AffilatePage from "./pages/affiliate";
// import UserInformationPage from "./pages/account/user-information";
// import AccountNotificationsPage from "./pages/account/notifications";
// import SearchResultsPage from "./pages/search";
// import BrandsListPage from "./pages/brands";
// import EnquiryPage from "./pages/enquiry-now";
// import NashikPage from "./pages/nashik";
import { fetchBrandsRequest } from "./store/brand/action";
import { fetchBannersRequest } from "./store/banner/action";
// import BrandDetails from "./components/brands/brandDetails";
import ReactGA4 from "react-ga4";
// import BlogPage from "./pages/blog/blog";
// import PostDetail from "./components/blog/post/postDetail";
// import RentalBookNowPage from "./pages/rental/bookNow";
// import GuestCheckoutPage from "./pages/account/guest-checkout";
// import PaymentSuccess from "./pages/account/payment-success";
// import BookingSuccess from "./pages/account/booking-success";
import FloatingButton from "./components/floatingActionButton";

// import LoginPage from "./pages/account/login";
const LoginPage = lazy(() => import("./pages/account/login"));
const Home = lazy(() => import("./pages/Home"));
const RentalPage = lazy(() => import("./pages/rental"));
const AboutUsPage = lazy(() => import("./pages/about-us"));
const ContactUsPage = lazy(() => import("./pages/contact-us"));
const FaqsPage = lazy(() => import("./pages/faqs"));
const BecomeAVendorPage = lazy(() => import("./pages/vendor/become-a-vendor"));
const VendorStorePage = lazy(() => import("./pages/vendor/vendor-store"));
const StoreList = lazy(() => import("./components/vendor/StoreList"));
const StoreList2 = lazy(() => import("./components/vendor/StoreList2"));
const ComparePage = lazy(() => import("./pages/account/compare"));
const ProductDefaultPage = lazy(() => import("./pages/product/default"));
const WishlistPage = lazy(() => import("./pages/account/wishlist"));
const ShoppingCartPage = lazy(() => import("./pages/account/shopping-cart"));
const CheckoutPage = lazy(() => import("./pages/account/checkout"));
const PrivacyPolicyPage = lazy(() => import("./pages/privacy-policy"));
const TermsConditionsPage = lazy(() => import("./pages/terms-conditions"));
const RegisterPage = lazy(() => import("./pages/account/register"));
const AffilatePage = lazy(() => import("./pages/affiliate"));
const UserInformationPage = lazy(() =>
  import("./pages/account/user-information")
);
const AccountNotificationsPage = lazy(() =>
  import("./pages/account/notifications")
);
const SearchResultsPage = lazy(() => import("./pages/search"));
const BrandsListPage = lazy(() => import("./pages/brands"));
const EnquiryPage = lazy(() => import("./pages/enquiry-now"));
const NashikPage = lazy(() => import("./pages/nashik"));
const BrandDetails = lazy(() => import("./components/brands/brandDetails"));
const BlogPage = lazy(() => import("./pages/blog/blog"));
const PostDetail = lazy(() => import("./components/blog/post/postDetail"));
const RentalBookNowPage = lazy(() => import("./pages/rental/bookNow"));
// const GuestCheckoutPage = lazy(() => import("./pages/account/guest-checkout"));
const PaymentSuccess = lazy(() => import("./pages/account/payment-success"));
const BookingSuccess = lazy(() => import("./pages/account/booking-success"));

const TRACKING_ID = "G-20YXKRD2TD";
ReactGA4.initialize(TRACKING_ID);
// import { getProductsByLocation } from "./store/product/action";
function App() {
  // const [subscribe, setSubscribe] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(fetchLocationData());
    dispatch(fetchCityListData());
    dispatch(fetchBrandsRequest());
    // console.log("calling banner request");
    dispatch(fetchBannersRequest());

    // dispatch(fe);
  }, [dispatch]);

  // console.log("locationData");
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubscribe(true);
  //   }, 10000);
  // });
  return (
    <div className="layout--default">
      <BrowserRouter>
        <HeaderDefault />
        <HeaderMobile />
        <NavigationList />
        <Suspense fallback={<div>Loading...</div>}>
          {/* <SubscribePopup active={subscribe} /> */}
          <Routes>
            {/* <Route path="/" element={<Navigate to="/home" />} /> */}

            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route
              path="/enquiry-now/:brand/:model/:id"
              element={<EnquiryPage />}
            />
            <Route path="/faqs" element={<FaqsPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {/* <Route path="/refund-return" element={<RefundReturnPage />} /> */}
            {/* <Route path="/shipping" element={<ShippingPage />} /> */}
            <Route path="/affiliate" element={<AffilatePage />} />
            <Route path="/terms-conditions" element={<TermsConditionsPage />} />
            <Route path="/account/compare" element={<ComparePage />} />
            {/* <Route path="/guest-checkout" element={<GuestCheckoutPage />} /> */}
            <Route path="/paymentsuccess" element={<PaymentSuccess />} />
            <Route path="/booking-success" element={<BookingSuccess />} />
            <Route path="/account/wishlist" element={<WishlistPage />} />
            <Route
              path="/account/notifications"
              element={<AccountNotificationsPage />}
            />
            <Route
              path="/account/shopping-cart"
              element={<ShoppingCartPage />}
            />
            <Route
              path="/account/user-information"
              element={<UserInformationPage />}
            />
            <Route path="/account/checkout" element={<CheckoutPage />} />
            <Route path="/account/login" element={<LoginPage />} />

            <Route path="/account/register" element={<RegisterPage />} />
            <Route
              path="/product/:brand/:model/:id"
              element={<ProductDefaultPage />}
            />
            <Route
              path="/vendor/become-a-vendor"
              element={<BecomeAVendorPage />}
            />
            <Route path="/brands" element={<BrandsListPage />} />
            <Route path="/brand/:brandId" element={<BrandDetails />} />
            <Route path="/nashik" element={<NashikPage />} />
            <Route path="/rental" element={<RentalPage />} />
            <Route
              path="/rental/book-now/:category"
              element={<RentalBookNowPage />}
            />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/blog/:title" element={<PostDetail />} />

            <Route path="/vendor/vendor-store" element={<VendorStorePage />} />
            <Route path="/vendor/store-list" element={<StoreList />} />
            <Route path="/vendor/store-list-2" element={<StoreList2 />} />
            {/* <Route path="/page-404" element={<Page404 />} /> */}
            <Route path="/*" element={<Navigate to="/" />} />
            {/* <Route path="/*" element={<Home />} /> */}
          </Routes>
        </Suspense>
        <FloatingButton />
        <FooterFullwidth />
      </BrowserRouter>
    </div>
  );
}

export default App;
