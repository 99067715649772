// saga.js

import { takeLatest, put, call, all, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./action";
import {
  API_BASE_URL,
  CONTACT_FORM_URL,
  ENQUIRY_FORM_URL,
} from "../../constants/apiConstants";
import axios from "axios";

// Worker Saga: Submit Enquiry Form
function* submitEnquiryForm(action) {
  try {
    const response = yield axios.post(
      API_BASE_URL + ENQUIRY_FORM_URL,
      action.payload.requestData
    );
    console.log(response);
    yield action.payload.onSuccess(response.data);
    // yield put({ type: actionTypes.SUBMIT_ENQUIRY_FORM_SUCCESS });
  } catch (error) {
    yield action.payload.onError(error);
  }
}

// Worker Saga: Submit Contact Form
function* submitContactForm(action) {
  try {
    const response = yield axios.post(
      API_BASE_URL + CONTACT_FORM_URL,
      action.payload.requestData
    );
    console.log(response);
    yield action.payload.onSuccess(response.data);
    // yield put({ type: actionTypes.SUBMIT_ENQUIRY_FORM_SUCCESS });
  } catch (error) {
    yield action.payload.onError(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actionTypes.SUBMIT_ENQUIRY_FORM_REQUEST, submitEnquiryForm),
  ]);
  yield all([
    takeEvery(actionTypes.SUBMIT_CONTACT_FORM_REQUEST, submitContactForm),
  ]);
}
