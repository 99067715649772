import React from "react";
import { Link } from "react-router-dom";

const FooterWidgets = () => {
  return (
    <div className="ps-footer__widgets">
      <aside className="widget widget_footer widget_contact-us">
        <h4 className="widget-title">Contact us</h4>
        <div className="widget_content">
          <p>Call us 24/7</p>
          {/* <h3>0253-4061534</h3> */}
          <h3>02269621007</h3>
          <p>
            {/* <Link to="https://maps.app.goo.gl/1FnQNf49UX6fPTzY7" target="blank"> */}
            Gurunanak dairy farm, Gondkar mala,Canal road,behind mirchi hotel,
            Aurangabad road,Nashik-422003, Maharashtra
            <br />
            {/* </Link> */}
            {/* <a href="mailto:info@shaktiev.com">info@shaktiev.com</a> */}
          </p>
          <ul className="ps-list--social">
            <li>
              <Link
                className="facebook"
                to="https://www.facebook.com/people/Shakti-EV/100094619513851/"
                aria-label="facebook Profile"
                target="_blank"
              >
                <i className="fa fa-facebook"></i>
              </Link>
            </li>
            <li>
              <Link
                className="twitter"
                to="https://twitter.com/shakti_ev"
                aria-label="twitter Profile"
                target="_blank"
              >
                <i className="fa fa-twitter"></i>
              </Link>
            </li>
            <li>
              <Link
                className="google-plus"
                to="https://www.linkedin.com/in/shakti-electric-vehicle-382861261"
                aria-label="linkedin Profile"
                target="_blank"
              >
                <i className="fa fa-linkedin"></i>
              </Link>
            </li>
            <li>
              <Link
                className="instagram"
                to="https://www.instagram.com/shakti_ev/"
                aria-label="instagram Profile"
                target="_blank"
              >
                <i className="fa fa-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget widget_footer">
        <h4 className="widget-title">Quick links</h4>
        <ul className="ps-list--link">
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>

          <li>
            <Link to="/terms-conditions">Term & Condition</Link>
          </li>
          {/* <li>
          <Link to="/refund-return">Refund & Return Policy</Link>
        </li>
        <li>
          <Link to="/shipping">Shipping</Link>
        </li>
        <li>
          <Link to="/blank">Return</Link>
        </li> */}
          <li>
            <Link to="/faqs">FAQs</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
          </li>
        </ul>
      </aside>
      <aside className="widget widget_footer">
        <h4 className="widget-title">Company</h4>
        <ul className="ps-list--link">
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/affiliate">
              <a>Become a partner</a>
            </Link>
          </li>
          <li>
            <Link to="/blank">Career</Link>
          </li>
        </ul>
      </aside>
      <aside className="widget widget_footer">
        <h4 className="widget-title">Bussiness</h4>
        <ul className="ps-list--link">
          <li>
            <Link to="/rental">
              <a>Rental</a>
            </Link>
          </li>
          {/* <li>
            <Link href="/account/checkout">
              <a>Service Center</a>
            </Link>
          </li> */}
          <li>
            <Link href="/account/user-information">
              <a>Site maps</a>
            </Link>
          </li>
          {/* <li>
          <Link href="/shop">
            <a></a>
          </Link>
        </li> */}
        </ul>
      </aside>
    </div>
  );
};

export default FooterWidgets;
