export const actionTypes = {
  FETCH_LOCATION_DATA: "FETCH_LOCATION_DATA",
  FETCH_CITY_LIST: "FETCH_CITY_LIST",
  SET_LOCATION_DATA: "SET_LOCATION_DATA",
  SET_CITY_LIST_DATA: "SET_CITY_LIST_DATA",
  SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
};
export const setSelectedLocation = (data) => {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    payload: data,
  };
};
export const setLocationData = (data) => {
  return {
    type: actionTypes.SET_LOCATION_DATA,
    payload: data,
  };
};
export const setCityListData = (data) => {
  return {
    type: actionTypes.SET_CITY_LIST_DATA,
    payload: data,
  };
};
export const fetchLocationData = () => {
  return {
    type: actionTypes.FETCH_LOCATION_DATA,
  };
};
export const fetchCityListData = () => {
  return {
    type: actionTypes.FETCH_CITY_LIST,
  };
};
