import React from "react";
import { useSelector } from "react-redux";
import MiniCart from "./MiniCart";
import AccountQuickLinks from "./AccountQuickLinks";
import { Link } from "react-router-dom";
import compareicon from "../../../assets/images/icon3-01.svg";

const MobileHeaderActions = () => {
  const auth = useSelector((state) => state.auth);
  const compare = useSelector((state) => state.compare);

  return (
    <div className="header__actions">
      <Link className="header__extra" to="/account/compare">
        <img
          src={compareicon}
          alt="React Logo"
          style={{ verticalAlign: "middle", lineHeight: "42px" }}
        />
        {/* <i className="icon-chart-bars"></i> */}
        <span>
          <i>{compare ? compare.compareTotal : compare.compareTotal}</i>
        </span>
      </Link>
      {/* <i className="icon-map-marker "></i> */}
      {/* <MiniCart /> */}
      {auth.isLoggedIn && Boolean(auth.isLoggedIn) === true ? (
        <AccountQuickLinks isLoggedIn={true} />
      ) : (
        <AccountQuickLinks isLoggedIn={false} />
      )}
    </div>
  );
};

export default MobileHeaderActions;
