// import { applyMiddleware, createStore } from "redux";
// import createSagaMiddleware from "redux-saga";
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import rootReducer from "./rootReducer";
// import rootSaga from "./rootSaga";

// const bindMiddleware = (middleware) => {
//   if (process.env.NODE_ENV !== "production") {
//     const { composeWithDevTools } = require("redux-devtools-extension");
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }
//   return applyMiddleware(...middleware);
// };

// const persistConfig = {
//   key: "martfury",
//   storage,
//   whitelist: ["cart", "compare", "auth", "wishlist", "shipping", "order"],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(persistedReducer, bindMiddleware([sagaMiddleware]));
// sagaMiddleware.run(rootSaga);

// export default store;

import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import axios from "axios";

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const persistConfig = {
  key: "martfury",
  storage,
  whitelist: ["cart", "compare", "auth", "wishlist", "shipping", "order"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (initialState) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    persistedReducer,
    initialState,
    bindMiddleware([sagaMiddleware])
  );

  const persistor = persistStore(store);
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;
