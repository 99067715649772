import React from "react";

const FooterCopyright = () => (
  <div className="ps-footer__copyright">
    <p>© 2020 shaktiEv. All Rights Reserved</p>
    <p>
      <span>We Using Safe Payment For:</span>
      <a href="#">
        <img
          src={require("../../../assets/images/payment-method/1.jpg")}
          alt="shaktiEv"
        />
      </a>
      <a href="#">
        <img
          src={require("../../../assets/images/payment-method/2.jpg")}
          alt="shaktiEv"
        />
      </a>
      <a href="#">
        <img
          src={require("../../../assets/images/payment-method/3.jpg")}
          alt="shaktiEv"
        />
      </a>
      <a href="#">
        <img
          src={require("../../../assets/images/payment-method/4.jpg")}
          alt="shaktiEv"
        />
      </a>
      <a href="#">
        <img
          src={require("../../../assets/images/payment-method/5.jpg")}
          alt="shaktiEv"
        />
      </a>
    </p>
  </div>
);

export default FooterCopyright;
