// reducer.js
import { actionTypes } from "./action";

const initialState = {
  banners: [],
  loading: false,
  error: null,
};

const banner = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_BANNERS_SUCCESS:
      return {
        ...state,
        banners: action.payload,
        loading: false,
        error: null,
      };

    case actionTypes.FETCH_BANNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default banner;
