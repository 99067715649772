import React from "react";
import { Link } from "react-router-dom";
// import { Rate } from "antd";
// import Rating from "../Rating";

const ProductResult = (props) => {
  const { product, setSearchPanel } = props;
  return (
    <div className="ps-product ps-product--wide ps-product--search-result">
      <div className="ps-product__thumbnail">
        <Link
          to={`/product/${product.brand.name}/${product.title}/${product._id}`}
          onClick={() => setSearchPanel(false)}
        >
          <img src={product.image[0].photos[0]} alt="ShaktiEV" />
        </Link>
      </div>
      <div className="ps-product__content">
        <Link
          className="ps-product__title"
          to={`/product/${product.brand.name}/${product.title}/${product._id}`}
          onClick={() => setSearchPanel(false)}
        >
          {product.title}
        </Link>
        {/* <div className="ps-product__rating">
          <Rating />
          <span>{product.ratingCount}</span>
        </div> */}
        {/* {product.sale === true ? (
          <p className="ps-product__price sale">
            {currency ? currency.symbol : "$"}
            {product.price}
            <del className="ml-1">
              {currency ? currency.symbol : "$"}
              {product.salePrice}
            </del>
          </p>
        ) : (
          <p className="ps-product__price">
            {currency ? currency.symbol : "$"}
            {product.price}
          </p>
        )} */}

        {product.salePrice !== undefined && product.salePrice !== null ? (
          <p className="ps-product__price sale d-flex flex-wrap">
            {/* {currency ? currency.symbol : "&#8377;"} */}
            {parseFloat(product.salePrice).toLocaleString("en-IN", {
              style: "currency",
              maximumFractionDigits: 0,
              currency: "INR",
            })}
            <del className="ml-2">
              {/* {currency ? currency.symbol : "&#8377;"} */}
              {parseFloat(product.price).toLocaleString("en-IN", {
                style: "currency",
                maximumFractionDigits: 0,
                currency: "INR",
              })}
            </del>
          </p>
        ) : (
          <p className="ps-product__price">
            {parseFloat(product.price).toLocaleString("en-IN", {
              style: "currency",
              maximumFractionDigits: 0,
              currency: "INR",
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProductResult;
