// export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const API_BASE_URL = "https://shaktiev.com/api/";
export const CITY_LIST_URL = "cities/";
export const PRODUCT_LIST_URL = "product/";
export const PRODUCT_BY_ID_URL = "product/productDetails?id=";
export const PRODUCT_LIST_BY_LOCATION_URL = "product/productListByLocation";
export const REGISTER_USER_URL = "user/register";
export const LOGIN_USER_URL = "user/login";
export const PRODUCT_LIST_BY_CITYID_URL = "product/productListByCity";
export const ADD_CONTACT_FORM_URL = "contactForm/addContactForm";
export const FEATURED_URL = "featured/";
export const BANNERS_URL = "banners/";
export const BRANDS_URL = "brands/";
export const BLOGS_URL = "blogs/";
export const BLOG_BY_TITLE_URL = "blogs/blogDetails?title=";
export const ENQUIRY_FORM_URL = "form/addEnquiryForm";
export const CONTACT_FORM_URL = "form/addEnquiryForm";
export const GET_KEY = "payment/getkey";
export const CHECKOUT = "payment/checkout";
export const PAYMENTVERIFICATION = `${API_BASE_URL}payment/paymentverification`;
