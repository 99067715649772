// actions.js
export const actionTypes = {
  FETCH_BANNERS_REQUEST: "FETCH_BANNERS_REQUEST",
  FETCH_BANNERS_SUCCESS: "FETCH_BANNERS_SUCCESS",
  FETCH_BANNERS_FAILURE: "FETCH_BANNERS_FAILURE",
};
export const fetchBannersRequest = () => ({
  type: actionTypes.FETCH_BANNERS_REQUEST,
});

export const fetchBannersSuccess = (banners) => ({
  type: actionTypes.FETCH_BANNERS_SUCCESS,
  payload: banners,
});

export const fetchBannersFailure = (error) => ({
  type: actionTypes.FETCH_BANNERS_FAILURE,
  payload: error,
});
