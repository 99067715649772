import React from "react";
import { Link } from "react-router-dom";
import MegaMenu from "./MegaMenu";
import MenuDropdown from "./MenuDropdown";

const Menu = ({ data, className }) => {
  return (
    <ul className={className}>
      {data &&
        data.map((item) => {
          if (item.subMenu) {
            // return <MenuDropdown menuData={item} key={item.text} />;
          } else if (item.megaContent) {
            return <MegaMenu menuData={item} key={item.text} />;
          } else {
            return (
              <li key={item.text}>
                {item.type === "dynamic" ? (
                  <Link
                    to={`${item.url}/[pid]`}
                    as={`${item.url}/${item.endPoint}`}
                  >
                    {item.text}
                  </Link>
                ) : (
                  <Link to={item.url} as={item.alias}>
                    {item.text}
                  </Link>
                )}
              </li>
            );
          }
        })}
    </ul>
  );
};

export default Menu;
