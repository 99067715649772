export const getStartDate = (currentDate = new Date()) => {
  let nextDate;

  if (currentDate.getHours() > 21 && currentDate.getHours() < 24) {
    nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day
    nextDate.setHours(9, 0, 0, 0);
  } else if (currentDate.getHours() >= 0 && currentDate.getHours() < 8) {
    nextDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      9,
      0,
      0,
      0
    );
  } else {
    nextDate = currentDate;
  }

  return nextDate;
};
export const getEndDate = (currentDate = new Date()) => {
  let nextDate;

  if (currentDate.getHours() > 21 && currentDate.getHours() < 24) {
    nextDate = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000); // Add 1 day
    nextDate.setHours(9, 0, 0, 0);
  } else if (currentDate.getHours() >= 0 && currentDate.getHours() < 8) {
    nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day
    nextDate.setHours(9, 0, 0, 0);
  } else {
    nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  }

  return nextDate;
};
