import React, { useState } from "react";
// import { Select } from "antd";
import { Link } from "react-router-dom";
import ProductResult from "../../elements/products/ProductSearchResult";
// import products from "../../../assets/data/product.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// const { Option } = Select;

const SearchHeader = () => {
  const navigate = useNavigate();
  const products = useSelector((state) => state.product.locationProducts);
  const [searchPanel, setSearchPanel] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [category, setCategory] = useState("type=All");
  const [searchProducts, setSearchProducts] = useState([]);

  const searchByProductName = (category, keyword) => {
    console.log({ category, keyword, products });
    const categoryValue = category.split("=")[1];
    console.log({ categoryValue });
    const matches = (
      categoryValue != "All" && products
        ? products.filter(
            (product) =>
              product.category === categoryValue ||
              product.speedType === categoryValue
          )
        : products
    ).filter(
      (product) =>
        product.title.toLowerCase().includes(keyword.toLowerCase()) ||
        product.brand.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setSearchProducts(matches);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // keyword = e.target.value;
    setKeyword(e.target.value);
    setSearchPanel(true);
    searchByProductName(category, keyword);
  };
  const handleCategory = (e) => {
    e.preventDefault();
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchPanel(false);
    // const keyword = e.target.value;

    navigate(`/search?keyword=${keyword}&${category}`);
  };
  const exampleCategories = [
    { value: "type=All", label: "All" },
    { value: "type=Bike", label: "Bike" },
    { value: "type=Scooter", label: "Scooter" },
    { value: "speedType=High", label: "High Speed" },
    { value: "speedType=Low", label: "Low Speed" },
  ];
  return (
    <form
      className="ps-form--quick-search"
      method="get"
      action="/"
      onSubmit={handleSubmit}
    >
      <div className="ps-form__categories">
        <select
          className="form-control"
          onChange={handleCategory}
          aria-labelledby="chooseCategory"
        >
          {exampleCategories.map((option) => (
            <option value={option.value} key={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <input
        className="form-control"
        type="text"
        placeholder="I'm shopping for..."
        onChange={handleSearch}
        value={keyword}
        onBlur={() => {
          // setKeyword("");
          setSearchPanel(false);
        }}
      />
      <button onClick={handleSubmit}>Search</button>
      <div
        className={`ps-panel--search-result${
          searchPanel && searchPanel === true ? " active " : ""
        }`}
      >
        <div className="ps-panel__content">
          {searchProducts.length > 0 ? (
            searchProducts.map((product) => (
              <ProductResult
                product={product}
                key={product._id}
                setSearchPanel={setSearchPanel}
              />
            ))
          ) : (
            <span>Not found! Try with another keyword.</span>
          )}
        </div>
        <div className="ps-panel__footer text-center">
          <Link
            to={`/search?keyword=${keyword}&${category}`}
            onClick={() => setSearchPanel(false)}
          >
            See all results
          </Link>
        </div>
      </div>
    </form>
  );
};

export default SearchHeader;
